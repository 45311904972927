import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { yyyymmdd } from 'src/app/util/date';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { IState } from 'src/app/core/interface/interface';
import { form } from 'src/app/util/constant/form.config';
import { ApiService } from 'src/app/shared/services/http/api.service';
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { DateFormatDirective } from '../directive/date-format.directive';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import {
  CommancssClassesType,
  commancssClasses,
} from 'src/app/util/constant/cssClass.config';
import { NotesService } from 'src/app/portal/notes/services/notes.service';
import { GmapService, Maps } from 'src/app/portal/trips/service/gmap.service';

@Component({
  selector: 'app-form-popup',
  templateUrl: './form-popup.component.html',
  styleUrls: ['./form-popup.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    MenuModule,
    OverlayPanelModule,
    InputMaskModule,
    DropdownModule,
    CalendarModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    InputNumberModule,
    DateFormatDirective,
    MultiSelectModule,
  ],
  providers: [DialogService, NotesService, GmapService],
})
export class FormPopupComponent implements OnInit {
  @ViewChildren('autoComplete') autoCompletes!: QueryList<AutoComplete>;
  @ViewChild('multiSelect') multiSelect!: MultiSelect;
  @ViewChildren('search') public searchElementRef!: QueryList<ElementRef>;
  @ViewChild('mapElement') mapElement!: ElementRef;

  commanCss: CommancssClassesType = commancssClasses;
  form!: FormGroup;
  states: IState[] = [];
  isSubmitting: boolean = false;
  labelClass: string = form.primary.labelClass;
  inputClass: string = form.primary.inputClass;
  errorTextClass: string = form.primary.errorTextClass;
  country: MenuItem[] | any;
  selectedCountry: any = 'usa';
  inputMask: any = '(999) 999-9999';
  selectedDrivers!: any[];
  private isDropdownOpen = false;

  constructor(
    private apiService: ApiService,
    private readonly spinner: SpinnerService,
    private readonly dialogRef: DynamicDialogRef,
    public readonly config: DynamicDialogConfig,
    private toastr: ToastrService,
    private nt: NotesService,
    private gmapService: GmapService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.country = [
      {
        label: 'Select Country',
        items: [
          {
            label: 'United States  +1',
            command: () => this.setCountry('usa'),
          },
          {
            label: 'India  +91',
            command: () => this.setCountry('ind'),
          },
        ],
      },
    ];
    this.initForm();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.initializeMap();
    });
  }
  // Set the selected country for mobile number input
  setCountry(countryCode: string) {
    this.selectedCountry = countryCode;
    this.inputMask = countryCode === 'usa' ? '(999) 999-9999' : '9999999999';
    this.form
      .get('mobile')
      ?.setValidators(this.getMobileNumberValidators(countryCode));
  }

  // Get mobile number validators based on the selected country
  getMobileNumberValidators(countryCode: string) {
    if (countryCode === 'usa') {
      return [
        Validators.required,
        Validators.pattern(/^\(\d{3}\) \d{3}-\d{4}$/),
      ];
    } else if (countryCode === 'ind') {
      return [
        Validators.required,
        Validators.pattern(/^(?:\+91|0)?[6789]\d{9}$/),
      ];
    }
    return null;
  }

  /** NgOnDestroy */
  ngOnDestroy(): void {
    this.form.reset();
  }

  get f() {
    return this.form.controls;
  }

  private initForm(): void {
    this.form = this.config.data.form;
    if (this.config.data.mode === 'update') {
      // For mobile number update
      const mobileFormControl = this.form.get('mobile');
      const dialCodeRegex = /^(?:\+1|\+91)/;
      const match = dialCodeRegex.exec(mobileFormControl?.value);
      if (match) {
        const dial_code = match[0];
        if (dial_code == '+1') {
          this.selectedCountry = 'usa';
          this.inputMask = '(999) 999-9999';
        } else {
          this.selectedCountry = 'ind';
          this.inputMask = '9999999999';
        }
        const mobile = mobileFormControl?.value?.substring(dial_code.length);
        mobileFormControl?.patchValue(mobile);
      }
      // For state update
      const stateControle: any = this.form.get('state_id');
      const foundStateObject =
        this.config.data.formActions.initailData.states.find(
          (obj: any) => obj.id == stateControle?.value
        );
      stateControle?.patchValue(foundStateObject);
      // For state update
      const genderControl: any = this.form.get('gender');
      const foundGender = this.config.data.formActions.initailData.gender.find(
        (obj: any) => obj.id == genderControl?.value
      );
      genderControl?.patchValue(foundGender);
      // For Expiration date
      const expiration_date: any = this.form.get('expiration_date');
      expiration_date?.patchValue(new Date(expiration_date.value));
      //For Date of birth
      const date_of_birth: any = this.form.get('date_of_birth');
      date_of_birth?.patchValue(new Date(date_of_birth.value));
    }
  }

  // Submit address form
  submit(): void {
    console.log(this.form.valid, this.form);
    if (this.form.invalid) {
      this.isSubmitting = true;
      return;
    }
    this.spinner.showSpinner();
    this.isSubmitting = false;
    var mobile: any = '';
    for (let i = 0; i < this.config.data.formHTML.length; i++) {
      const el: any = this.config.data.formHTML[i];
      if (el?.dateFormatValue == 'yyyy-mm-dd') {
        this.form
          .get(el.formControlName)
          ?.setValue(yyyymmdd(this.form.get(el.formControlName)?.value));
      }
      if (el?.formatMobile) {
        if (this.selectedCountry === 'usa') {
          const phoneNumber = this.form.get(el.formControlName)?.value;
          const formattedPhoneNumber = phoneNumber.replace(/\D/g, '');
          mobile = '+1' + formattedPhoneNumber;
        } else if (this.selectedCountry === 'ind') {
          mobile = '+91' + this.form.get(el.formControlName)?.value;
        }
        this.form.get(el.formControlName)?.setValue(mobile);
      }
    }
    let method;
    const payload: any = { ...this.form.value, ...this.config.data.userType };

    // New code for ID extraction
    ['state_id', 'gender', 'user_sub_type'].forEach((prop) => {
      if (payload[prop]?.id) {
        payload[prop] = payload[prop].id;
      }
    });

    if (this.config?.data?.omitEmptyValues) {
      Object.keys(payload).forEach((item: any) => {
        if (!item) delete payload[item];
      });
    }
    if (this.config.data.mode == 'create') {
      method = this.apiService.httpPost(this.config.data.submitUrl, payload);
    } else {
      method = this.apiService.httpPut(this.config.data.submitUrl, payload);
    }
    method.subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.spinner.hideSpinner();
          if (this.config.data.closeOnComplete) {
            this.dialogRef.close(res?.data ? res?.data : true);
          }
        }
      },
      error: () => this.spinner.hideSpinner(),
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  /** On focus */
  onshow(event: any, type: string) {
    // Prevent multiple triggers
    if (this.isDropdownOpen) return;

    this.isDropdownOpen = true;
    setTimeout(() => {
      const autoComplete = this.autoCompletes.find((ac) =>
        ac.el.nativeElement.contains(event.target)
      );
      if (autoComplete) {
        autoComplete.show();
        // Update the data based on type
        this.updateDropdownData(type);
      }
    });
  }

  /** Filter Item */
  filterItems(event: AutoCompleteCompleteEvent, type: string) {
    // Prevent multiple triggers
    if (this.isDropdownOpen) return;

    this.isDropdownOpen = true;
    setTimeout(() => {
      const autoComplete = this.autoCompletes.find((ac) =>
        ac.el.nativeElement.contains(event.originalEvent?.target)
      );
      if (autoComplete) {
        autoComplete.show();
        // Update the data based on type
        this.updateDropdownData(type);
      }
    });
  }

  // Helper method to update dropdown data
  private updateDropdownData(type: string) {
    switch (type) {
      case 'state':
        this.config.data.formActions.initailData.states = [
          ...this.config.data.formActions.initailData.states,
        ];
        break;
      case 'gender':
        this.config.data.formActions.initailData.gender = [
          ...this.config.data.formActions.initailData.gender,
        ];
        break;
      case 'type':
        this.config.data.formActions.initailData.contractorSubType = [
          ...this.config.data.formActions.initailData.contractorSubType,
        ];
        break;
      // Add more cases as needed
    }
  }

  /** Filter Item For Input Search */
  async inputFilterItems(event: any, type: string) {
    switch (type) {
      // Enrollee
      case 'tenant':
        const res: any[] = await this.nt.getInitialDataEnrollee(event.filter);
        if (res.length) {
          this.config.data.formActions.initailData.tenant = [
            ...this.config.data.formActions.initailData.tenant,
          ];
        }
        break;
      case 'driver':
        const res1: any[] = await this.nt.getInitialDataDriver(event.filter);
        if (res1.length) {
          this.config.data.formActions.initailData.tenant = [
            ...this.config.data.formActions.initailData.tenant,
          ];
        }
        break;
      case 'trip':
        const res2: any[] = await this.nt.getInitialTrip(event.filter);
        if (res2.length) {
          this.config.data.formActions.initailData.tenant = [
            ...this.config.data.formActions.initailData.tenant,
          ];
        }
        break;
    }
  }

  /** Dismiss multi select */
  dismissMultiSelect() {
    this.multiSelect.hide();
  }

  /** Done multi select */
  doneMultiSelect() {
    this.multiSelect.hide();
  }

  // Add this method to handle dropdown close
  onHide() {
    this.isDropdownOpen = false;
  }
  private onPlaceChange(place: google.maps.places.PlaceResult): void {
    if (!place.geometry || !place.geometry.location) {
      return;
    }

    const latitude = place.geometry.location.lat();
    const longitude = place.geometry.location.lng();

    // Initialize variables for address components
    let address = '';
    let city = '';
    let state = '';
    let zipCode = '';

    // Extract components from address
    if (place.address_components) {
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number':
            address = `${component.long_name} `;
            break;

          case 'route':
            address += component.long_name;
            break;

          case 'locality':
            city = component.long_name;
            break;

          case 'administrative_area_level_1':
            state = component.long_name;
            break;

          case 'postal_code':
            zipCode = component.long_name;
            break;
        }
      }
    }

    // Patch values to the form
    this.form.patchValue({
      address: address || place.formatted_address,
      city: city,
      state: state,
      zipcode: zipCode,
      location_lat_long: `${latitude},${longitude}`,
    });
    console.log(this.form);
  }

  private initAutocomplete(maps: Maps): void {
    this.searchElementRef.forEach((searchElement, index) => {
      let autocomplete = new maps.places.Autocomplete(
        searchElement.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          this.onPlaceChange(autocomplete.getPlace());
        });
      });
    });
  }
  private initializeMap(): void {
    if (!this.mapElement) {
      return;
    }

    this.gmapService.api
      .then((maps) => {
        try {
          this.initAutocomplete(maps);
        } catch (error) {
          console.error('Error initializing map:', error);
        }
      })
      .catch((error) => {
        console.error('Error loading Google Maps API:', error);
      });
  }
}
