import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApiService } from 'src/app/shared/services/http/api.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { driverFormHtml, formActions } from './user.config';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { DialogService } from 'primeng/dynamicdialog';
import { FormPopupComponent } from 'src/app/@standalone/form-popup/form-popup.component';
import { IVehicle } from 'src/app/shared/interfaces/vehicle.interface';
import { vehicleFormHtml } from './vehicles/vehicle.config';
import {
  mailingAddressForm,
  physicalAddressForm,
} from './user-detail/address.config';
import { AddressFormComponent } from 'src/app/@standalone/address-form/address-form.component';
import {
  enrolleeProfile,
  medicalProvider,
  transportationProvider,
} from '../profile/profile/profile.confic';
import { UserTypeIdEnum } from 'src/app/shared/interfaces/user.interface';
import { state } from '@angular/animations';

@Injectable()
export class UserService {
  UserTypeIdEnum!: UserTypeIdEnum;

  // Tenant Form
  tanentForm: FormGroup = this.fb.group({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
    ]),
    mobile: new FormControl('', [Validators.required]),
    state_id: new FormControl(''),
    zipcode: new FormControl(''),
    address: new FormControl('', [Validators.required]),
    state: new FormControl(''),
  });

  // Contractor Form
  contractorForm: FormGroup = this.fb.group({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
    ]),
    mobile: new FormControl('', [Validators.required]),
    user_sub_type: new FormControl('', [Validators.required]),
    date_of_birth: new FormControl('', [Validators.required]),
    zipcode: new FormControl(''),
    state_id: new FormControl(''),
    gender: new FormControl('', [Validators.required]),
    city: new FormControl(''),
    address: new FormControl('', [Validators.required]),
    state: new FormControl(''),
  });

  // User physical address form
  physicalAddressForm: FormGroup = this.fb.group({
    address: new FormControl('', [Validators.required]),
    location_lat_long: new FormControl('', [Validators.required]),
    address2: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    state_id: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(5),
    ]),
    apartment: [''],
    floor: [''],
    elevator: [false],
    pickup_point: ['frontdesk'],
  });

  // User physical address form
  mailingAddressForm: FormGroup = this.fb.group({
    address: new FormControl('', [Validators.required]),
    address2: new FormControl(''),
    city: new FormControl('', [Validators.required]),
    state_id: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(5),
    ]),
  });

  // Vehicle form
  vehicleForm: FormGroup = this.fb.group({
    vehicle_plate: new FormControl('', [Validators.required]),
    vin: new FormControl('', [Validators.required]),
    no_of_seat: new FormControl('', [Validators.required]),
    state_id: new FormControl('', [Validators.required]),
    expiration_date: new FormControl('', [Validators.required]),
    make: new FormControl('', [Validators.required]),
    model: new FormControl('', [Validators.required]),
    year: new FormControl('', [Validators.required]),
    color: new FormControl('', [Validators.required]),
  });

  /** Vehicle id */
  vehicleAssociatedDriverForm: FormGroup = this.fb.group({
    drivers_id: new FormControl('', [Validators.required]),
  });

  /** Enrollee porifile update */
  tenant: FormGroup = this.fb.group({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
    ]),
    mobile: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(5),
    ]),
    state_id: new FormControl('', [Validators.required]),
    cin_medical_id: new FormControl('', [Validators.required]),
    social_security_number: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    date_of_birth: new FormControl('', [Validators.required]),
  });

  /** Medical provider profile upadate */
  medicalProvider: FormGroup = this.fb.group({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
    ]),
    mobile: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(5),
    ]),
    state_id: new FormControl('', [Validators.required]),
    facility_department: new FormControl('', [Validators.required]),
  });

  /** Transportation provider profile update */
  transportationProvider: FormGroup = this.fb.group({
    service_provider_name: new FormControl('', [Validators.required]),
    medicaid_provider_id: new FormControl('', [Validators.required]),
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/),
    ]),
    mobile: new FormControl('', [Validators.required]),
    zipcode: new FormControl('', [
      Validators.required,
      Validators.maxLength(6),
      Validators.minLength(5),
    ]),
    state_id: new FormControl('', [Validators.required]),
  });

  constructor(
    private readonly spinner: SpinnerService,
    private fb: FormBuilder,
    private apiService: ApiService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private dialogService: DialogService
  ) {
    this.getStateData();
  }

  getInitialData(): Promise<any[]> {
    const urls: string[] = ['user/list_driver'];
    return new Promise((resolve, reject) => {
      this.apiService.forkJoin(urls).subscribe({
        next: (res: any) => {
          if (res.length) {
            res[0].data = res[0].data.map((i: any) => {
              return { ...i, displayName: i.first_name + ' ' + i.last_name };
            });
            formActions.initailData.drivers = res[0].data;
            formActions.initailData.isInitialDataLoaded = true;
            resolve(res);
          }
        },
        error: (err: any) => {
          console.log(err);
          reject(false);
        },
        complete: () => {},
      });
    });
  }

  getStateData() {
    this.apiService.httpGet('state').subscribe({
      next: (res: any) => {
        if (res.success && (res.status === 200 || res.status === 201)) {
          // Update the initailData object with the retrieved states data
          formActions.initailData.states = res.data;
        } else {
        }
      },
    });
  }

  async actionDecider(event: string, user: any) {
    let response: any;
    if (event == 'delete') {
      response = await this.deleteUser(user);
    } else if (event == 'verify') {
      response = await this.userVerify(user);
    } else if (event == 'activate' || event == 'deactivate') {
      response = await this.userAccountAction(user);
    } else if (event == 'block' || event == 'unblock') {
      response = await this.userAccountActionBlockUnblock(user);
    } else if (event == 'edit') {
      response = await this.editUser(user);
    } else if (event == 'addressEdit') {
      response = await this.editAddress(user);
    }
    return response;
  }

  // Delete user
  private async deleteUser(user: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.spinner.showSpinner();
      this.apiService.httpDelete(`${'user/delete/' + user.user_id}`).subscribe({
        next: (res: any) => {
          this.spinner.hideSpinner();
          resolve(true);
        },
        error: () => {
          this.spinner.hideSpinner();
          reject(false);
        },
      });
    });
  }

  // Verify User
  private userVerify(user: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.spinner.showSpinner();
      this.apiService
        .httpPut(`${'user/verify/' + user.user_id}`, {})
        .subscribe({
          next: (res: any) => {
            this.spinner.hideSpinner();
            resolve(true);
          },
          error: () => {
            this.spinner.hideSpinner();
            reject(false);
          },
        });
    });
  }

  // Account action
  private userAccountAction(user: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.spinner.showSpinner();
      let status!: string;
      if (user.status == '2' || user.status == '4') {
        status = 'active';
      } else if (user.status == '3') {
        status = 'inactive';
      }
      const payload: any = { account_status: status };
      this.apiService
        .httpPut(`${'user/account_action/' + user.user_id}`, payload)
        .subscribe({
          next: (res: any) => {
            this.spinner.hideSpinner();
            resolve(true);
          },
          error: () => {
            this.spinner.hideSpinner();
            reject(false);
          },
        });
    });
  }

  // User accout block unblock
  private userAccountActionBlockUnblock(user: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.spinner.showSpinner();
      let status!: string;
      if (user.status == '6') {
        status = 'unblock';
      } else {
        status = 'block';
      }
      this.apiService
        .httpPut(`${'user/block_action/' + user.user_id + '/'}${status}`, {})
        .subscribe({
          next: (res: any) => {
            this.spinner.hideSpinner();
            resolve(true);
          },
          error: () => {
            this.spinner.hideSpinner();
            reject(false);
          },
        });
    });
  }

  // Edit user
  private editUser(user: any): Promise<boolean> {
    this.contractorForm.patchValue(user);
    const obj: any = {
      header: 'Update Driver',
      width: '800px',
      maximizable: false,
      dismissableMask: true,
      styleClass: 'stand-alone-form',
      data: {
        form: this.contractorForm,
        formHTML: driverFormHtml,
        formActions: formActions,
        submitUrl: `${'user/update'}/${user.user_id}`,
        mode: 'update',
        closeOnComplete: true,
        omitEmptyValues: true,
        userType: {
          user_type: user.user_type,
          user_sub_type: user.user_sub_type,
        },
      },
    };
    return new Promise((resolve, reject) => {
      this.dialogService
        .open(FormPopupComponent, obj)
        .onClose.subscribe((dialogResponse) => {
          if (dialogResponse) resolve(true);
          else resolve(false);
        });
    });
  }

  // Edit user address
  private editAddress(user: any): Promise<boolean> {
    this.physicalAddressForm.patchValue(user.addresses[0]);
    this.mailingAddressForm.patchValue(user.addresses[1]);
    const obj: any = {
      header: 'Update address',
      width: '900px',
      maximizable: false,
      dismissableMask: true,
      data: {
        form1: this.physicalAddressForm,
        formHTML1: physicalAddressForm,
        form2: this.mailingAddressForm,
        formHTML2: mailingAddressForm,
        formActions: formActions,
        submitUrl: `${'user/update_address'}/${user.user_id}`,
        mode: 'update',
        closeOnComplete: true,
        omitEmptyValues: true,
        userType: {
          user_type: user.user_type,
          user_sub_type: user.user_sub_type,
        },
      },
    };
    return new Promise((resolve, reject) => {
      this.dialogService
        .open(AddressFormComponent, obj)
        .onClose.subscribe((dialogResponse) => {
          if (dialogResponse) resolve(true);
          else resolve(false);
        });
    });
  }

  // Vehicle action decider
  async actionDeciderVehicles(event: string, vehicle: IVehicle) {
    let response: any;
    if (event == 'delete') {
      response = await this.deleteVehicle(vehicle);
    } else if (event == 'verify') {
      response = await this.vehicleVerify(vehicle);
    } else if (event == 'activate' || event == 'deactivate') {
      response = await this.vehicleAccountAction(vehicle);
    } else if (event == 'edit') {
      response = await this.editVehicle(vehicle);
    }
    return response;
  }

  // edit vehicle
  editVehicle(data: any) {
    this.vehicleForm.patchValue(data);
    const obj: any = {
      header: 'Edit Vehicle',
      width: '800px',
      maximizable: false,
      dismissableMask: true,
      styleClass: 'stand-alone-form',
      data: {
        form: this.vehicleForm,
        formHTML: vehicleFormHtml,
        formActions: formActions,
        submitUrl: `${'vehicle/update'}/${data.vehicle_id}`,
        mode: 'update',
        closeOnComplete: true,
        omitEmptyValues: true,
        userType: { user_type: null, user_sub_type: null },
      },
    };
    return new Promise((resolve, reject) => {
      this.dialogService
        .open(FormPopupComponent, obj)
        .onClose.subscribe((dialogResponse) => {
          if (dialogResponse) resolve(true);
          else resolve(false);
        });
    });
  }

  // Delete vehicle
  private async deleteVehicle(vehicle: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.spinner.showSpinner();
      this.apiService
        .httpDelete(`${'vehicle/delete/' + vehicle.vehicle_id}`)
        .subscribe({
          next: (res: any) => {
            this.spinner.hideSpinner();
            resolve(true);
          },
          error: () => {
            this.spinner.hideSpinner();
            reject(false);
          },
        });
    });
  }

  // Verify vehicle
  private vehicleVerify(vehicle: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.spinner.showSpinner();
      this.apiService
        .httpPut(`${'vehicle/verify/' + vehicle.vehicle_id}`, {})
        .subscribe({
          next: (res: any) => {
            this.spinner.hideSpinner();
            resolve(true);
          },
          error: () => {
            this.spinner.hideSpinner();
            reject(false);
          },
        });
    });
  }

  // Account action
  private vehicleAccountAction(vehicle: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.spinner.showSpinner();
      let status!: string;
      if (vehicle.status == '2' || vehicle.status == '4') {
        status = 'active';
      } else if (vehicle.status == '3') {
        status = 'inactive';
      }
      const payload: any = { vehicle_status: status };
      this.apiService
        .httpPut(`${'vehicle/action/' + vehicle.vehicle_id}`, payload)
        .subscribe({
          next: (res: any) => {
            this.spinner.hideSpinner();
            resolve(true);
          },
          error: () => {
            this.spinner.hideSpinner();
            reject(false);
          },
        });
    });
  }

  /** Action decider for logged user profile update */
  async actionDeciderLoggedUserProfile(event: string, user: any) {
    let response: any;
    if (event == 'edit') {
      response = await this.editLoggedUserProfile(user);
    } else if (event == 'addressEdit') {
      response = await this.editAddress(user);
    }
    return response;
  }

  /** Edit logged User Profle */
  editLoggedUserProfile(user: any) {
    let userForm: any;
    let userFormHtml: any;
    switch (user?.user_type) {
      case UserTypeIdEnum.TENANT:
        this.tenant.patchValue(user);
        userForm = this.tenant;
        userFormHtml = enrolleeProfile;
        break;
      case UserTypeIdEnum.MEDICAL_PROVIDER:
        this.medicalProvider.patchValue(user);
        userForm = this.medicalProvider;
        userFormHtml = medicalProvider;
        break;
      case UserTypeIdEnum.SERVICE_PROVIDER:
        this.transportationProvider.patchValue(user);
        userForm = this.transportationProvider;
        userFormHtml = transportationProvider;
        break;
      default:
        return Promise.resolve(false); // Handle other user types as needed.
    }
    const dialogData: any = {
      header: 'Update Profile',
      width: '900px',
      maximizable: false,
      dismissableMask: false,
      styleClass: 'stand-alone-form',
      data: {
        form: userForm,
        formHTML: userFormHtml,
        formActions: formActions,
        submitUrl: `${'user/update'}/${user.user_id}`,
        mode: 'update',
        closeOnComplete: true,
        omitEmptyValues: true,
        userType: {
          user_type: user.user_type,
          user_sub_type: user.user_sub_type,
        },
      },
    };

    return new Promise<boolean>((resolve) => {
      this.dialogService
        .open(FormPopupComponent, dialogData)
        .onClose.subscribe((dialogResponse) => {
          resolve(!!dialogResponse);
        });
    });
  }
}
