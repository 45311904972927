<div class="dialog-container stand-alone-form">
  <form class="p-6 driver-create" [formGroup]="form">
    <div class="grid grid-cols-12 gap-4">
      <div
        class="{{
          config.data?.parentCol ? config.data?.parentCol : 'col-span-6'
        }} max-[765px]:col-span-12"
        *ngFor="let item of config.data.formHTML"
      >
        <div class="grid grid-cols-12">
          <div class="col-span-4">
            <label
              class="{{ labelClass }} whitespace-pre-line"
              [ngClass]="{ 'mt-[13px]': item.label == 'Phone' }"
            >
              {{ item.label }}
            </label>
          </div>
          <div class="col-span-8">
            <ng-container *ngIf="item.type == 'text'">
              <input
                pInputText
                type="text"
                [placeholder]="item.placeholder"
                [formControlName]="item.formControlName"
                class="{{ inputClass }}"
                [maxlength]="item?.maxLength"
                [minLength]="item?.minLength"
              />
            </ng-container>
            <ng-container *ngIf="item.type == 'textarea'">
              <textarea
                rows="5"
                pInputTextarea
                [placeholder]="item.placeholder"
                class="{{ inputClass }} text-area"
                [formControlName]="item.formControlName"
              ></textarea>
            </ng-container>
            <ng-container *ngIf="item.type == 'email'">
              <input
                pInputText
                type="email"
                [placeholder]="item.placeholder"
                [formControlName]="item.formControlName"
                class="{{ inputClass }}"
              />
            </ng-container>
            <ng-container *ngIf="item.type == 'inputMask'">
              <div class="flex mt-2">
                <button
                  class="flex-shrink-0 z-10 inline-flex items-center px-2 text-sm font-medium text-center text-gray-500 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                  type="button"
                  (click)="code.toggle($event)"
                >
                  <img
                    *ngIf="selectedCountry == 'usa'"
                    src="/assets/portal/icons/united-states-of-america.png"
                    alt="flag"
                    class="flag"
                  />
                  <img
                    *ngIf="selectedCountry == 'ind'"
                    src="/assets/portal/icons/india-flag-icon.svg"
                    alt="flag"
                    class="flag"
                  />
                  <svg
                    class="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>
                <p-menu
                  #code
                  [model]="country"
                  [appendTo]="'body'"
                  styleClass="custom-menu-select"
                  [popup]="true"
                ></p-menu>
                <p-inputMask
                  mask="{{ inputMask }}"
                  styleClass="{{ inputClass }}"
                  [formControlName]="item.formControlName"
                  [placeholder]="item.placeholder"
                >
                </p-inputMask>
              </div>
            </ng-container>
            <ng-container *ngIf="item.type == 'inputNumber'">
              <p-inputNumber
                styleClass="number-input"
                [placeholder]="item.placeholder"
                [formControlName]="item.formControlName"
                inputStyleClass="{{ inputClass }}"
              >
              </p-inputNumber>
            </ng-container>
            <ng-container *ngIf="item.type == 'googlePlaceAutoComplate'">
              <input
                #search
                pInputText
                type="text"
                [placeholder]="item.placeholder"
                [formControlName]="item.formControlName"
                class="{{ inputClass }}"
              />
            </ng-container>
            <div #mapElement id="map"></div>
            <ng-container *ngIf="item.type == 'dropdown'">
              <p-autoComplete
                #autoComplete
                (onFocus)="onshow($event, item.label.toLowerCase())"
                (completeMethod)="filterItems($event, item.label.toLowerCase())"
                (onHide)="onHide()"
                [formControlName]="item.formControlName"
                [suggestions]="
                  config.data.formActions.initailData[item.optionListKey]
                "
                class="p-inputtext-sm w-full"
                [field]="item.optionLabel"
                [readonly]="true"
                [dropdown]="true"
                [placeholder]="item.placeholder"
                [showClear]="false"
                appendTo="body"
              >
              </p-autoComplete>
            </ng-container>
            <ng-container *ngIf="item.label == 'Priority'">
              <p-dropdown
                [options]="
                  config.data.formActions.initailData[item.optionListKey]
                "
                [optionLabel]="item.optionLabel"
                appendTo="body"
                [optionValue]="item.optionValue"
                [placeholder]="item.placeholder"
                [formControlName]="item.formControlName"
                styleClass="custom-dropdown {{
                  inputClass
                }} form-control w-full"
              >
              </p-dropdown>
            </ng-container>

            <ng-container *ngIf="item.type == 'dropdown-with-search'">
              <p-dropdown
                [options]="
                  config.data.formActions.initailData[item.optionListKey]
                "
                [optionLabel]="item.optionLabel"
                appendTo="body"
                [optionValue]="item.optionValue"
                [filter]="true"
                (onFilter)="inputFilterItems($event, item.optionListKey)"
                [placeholder]="item.placeholder"
                [formControlName]="item.formControlName"
                styleClass="custom-dropdown {{
                  inputClass
                }} form-control w-full"
              >
              </p-dropdown>
            </ng-container>

            <ng-container *ngIf="item.type == 'multi-dropdown'">
              <!-- <p-multiSelect [options]="config.data.formActions.initailData[item.optionListKey]"
                                [formControlName]="item.formControlName" [optionLabel]="item.optionLabel"
                                [optionValue]="item.optionValue" [placeholder]="item.placeholder" appendTo="body"
                                class="p-inputtext-sm w-full">
                            </p-multiSelect> -->

              <p-multiSelect
                #multiSelect
                [options]="
                  config.data.formActions.initailData[item.optionListKey]
                "
                [formControlName]="item.formControlName"
                appendTo="body"
                [optionLabel]="item.optionLabel"
                [placeholder]="item.placeholder"
                [optionValue]="item.optionValue"
                class="p-inputtext-sm w-full"
              >
                <ng-template let-driver pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <div>
                      {{ driver.first_name }} {{ driver.last_name }}
                      {{ driver.mobile }}
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="footer">
                  <hr />
                  <div class="flex justify-end p-5">
                    <button
                      (click)="dismissMultiSelect()"
                      class="text-[13px] text-[#334D6E] bg-[#FFFFFF] font-medium"
                      type="button"
                    >
                      Dismiss
                    </button>
                    <button
                      (click)="doneMultiSelect()"
                      class="text-[13px] ml-10 mr-5 text-[#2C71C3] bg-[#FFFFFF] font-medium"
                      type="button"
                    >
                      Done
                    </button>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-container>
            <ng-container *ngIf="item.type == 'calendar'">
              <p-calendar
                [showIcon]="true"
                [formControlName]="item.formControlName"
                appDateFormat
                inputStyleClass="expiration p-inputtext-sm w-full"
                placeholder="MM/DD/YYYY"
                appendTo="body"
              >
              </p-calendar>
            </ng-container>
            <div
              *ngIf="
                isSubmitting &&
                f[item.formControlName].invalid &&
                f[item.formControlName].errors
              "
              class="{{ errorTextClass }}"
            >
              <ng-container *ngIf="item?.requiredMsg">
                <div [hidden]="!f[item.formControlName].errors!['required']">
                  {{ item.requiredMsg }}
                </div>
              </ng-container>
              <ng-container *ngIf="item?.patternMsg">
                <div
                  [hidden]="
                    !f[item.formControlName].errors!['pattern'] &&
                    f[item.formControlName].errors!['required']
                  "
                >
                  {{ item.patternMsg }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="text-right processbutton mt-5">
    <button
      type="button"
      (click)="cancel()"
      class="{{ commanCss?.dialogCancelBtn }} mr-5"
    >
      {{ config.data.formActions.cancelBtn.label }}
    </button>

    <button
      type="submit"
      class="{{ commanCss?.dialogSubmitBtn }} mr-10"
      (click)="submit()"
    >
      {{ config.data.mode === "update" ? "Update" : "Submit" }}
      <!-- {{ config.data.formActions.okbtn.label }} -->
    </button>
  </div>
</div>
