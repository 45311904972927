import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './core/interceptor/api.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UnAuthGuard } from './core/guard/unauth-guard.service';
import { InternetInterceptor } from './core/interceptor/conectivity.interceptor';

/****************** PRIMENG MODULES  *******************/
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { DialogService } from 'primeng/dynamicdialog';
import { UserService } from './portal/manage-user/user.service';
import { ConfirmationService } from 'primeng/api';
import { MainPageModule } from './portal/main-page/main-page.module';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';

const PRIME_MODULES = [
  ToastModule,
  DropdownModule,
  AvatarGroupModule,
  AvatarModule,
  BadgeModule,
  MenuModule,
]
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MainPageModule,
    SpinnerComponent,
    NgxStripeModule.forRoot(environment.stripeTestKey),
    ...PRIME_MODULES
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InternetInterceptor, multi: true },
    UnAuthGuard,
    DialogService,
    UserService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
