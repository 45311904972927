export enum REQUEST_STATUS {
  MarketPlaceRequest = 'marketplace_request',
  Accepted = 'accepted',
  AcceptedBySP = 'accepted_sp',
  ArrivedAtPickup = 'arrived_at_pickup',
  Assigned = 'assigned',
  AssignedToSP = 'assigned_sp',
  PendingContractor = 'pending_contractor',
  AttentionRequiredPayment = 'attention_required_payment',
  AttentionRequiredPaymentPaid = 'attention_required_payment_paid',
  AttentionRequiredQuery = 'attention_required_query',
  AttentionRequiredQueryPaid = 'attention_required_query_paid',
  AttentionRequiredQueryResolved = 'attention_required_query_resolved',
  CancelledDoorman = 'cancelled_doorman',
  CancelledFrontdesk = 'cancelled_frontdesk',
  CancelledManagement = 'cancelled_management',
  CancelledServiceProvider = 'cancelled_service_provider',
  CancelledTenant = 'cancelled_tenant',
  CancelledContractor = 'cancelled_contractor',
  Confirmed = 'confirmed',
  ConfirmedDoorman = 'confirmed_doorman',
  ConfirmedFrontdesk = 'confirmed_frontdesk',
  ConfirmedTenant = 'confirmed_tenant',
  Decline = 'decline',
  DeliveredDoorman = 'delivered_doorman',
  DeliveredFrontdesk = 'delivered_frontdesk',
  DeliveredTenant = 'delivered_tenant',
  DropOffAtShop = 'drop_off_at_shop',
  EnRouteToShop = 'en_route_to_shop',
  FeedbackAdded = 'feedback_added',
  NewRequest = 'new_request',
  NewScheduledRequest = 'new_scheduled_request',
  OutForDelivery = 'out_for_delivery',
  Paid = 'paid',
  PersonnelConfirmed = 'personnel_confirmed',
  PersonnelDeclined = 'personnel_declined',
  PersonnelPending = 'personnel_pending',
  PickingUp = 'picking_up',
  Processing = 'processing',
  Queued = 'queued',
  Requesting = 'requesting',
  SameAbove = 'same_above',
  ServiceProvider = 'service_provider',
  StartPickup = 'start_pickup',
  TipAdded = 'tip_added',
  Unassigned = 'unassigned',
  WorkDone = 'work_done',
  WorkInProgress = 'work_in_progress',
  Pending = 'pending',
}

export interface StatusConfig {
  leftTooltip: string;
  rightTooltip: string;
  leftActionDisabled: boolean;
  rightActionDisabled: boolean;
  leftTooltipStyleClass: string;
  rightTooltipStyleClass: string;
  nextStatus: string;
  prevStatus: string;
  leftSpinner: boolean;
  rightSpinner: boolean;
}

export type StatusConfigMap = Record<REQUEST_STATUS, StatusConfig>;

export const statusConfig: StatusConfigMap = {
  [REQUEST_STATUS.MarketPlaceRequest]: {
    leftTooltip: 'Cancel',
    rightTooltip: 'Accept',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'light_red',
    rightTooltipStyleClass: 'light_blue',
    nextStatus: REQUEST_STATUS.Accepted,
    prevStatus: 'cancel',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.NewRequest]: {
    leftTooltip: '',
    rightTooltip: 'Accept',
    leftActionDisabled: true,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: 'accept',
    nextStatus: REQUEST_STATUS.Accepted,
    prevStatus: 'cancel',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Accepted]: {
    leftTooltip: 'Decline',
    rightTooltip: '',
    leftActionDisabled: false,
    rightActionDisabled: true,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: '',
    prevStatus: REQUEST_STATUS.Decline,
    nextStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Pending]: {
    leftTooltip: 'Unassign',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    prevStatus: REQUEST_STATUS.Unassigned,
    nextStatus: REQUEST_STATUS.Confirmed,
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Queued]: {
    leftTooltip: 'Unassign',
    rightTooltip: '',
    leftActionDisabled: false,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: REQUEST_STATUS.Unassigned,
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Confirmed]: {
    leftTooltip: '',
    rightTooltip: 'Start Trip',
    leftActionDisabled: true,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: REQUEST_STATUS.StartPickup,
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Decline]: {
    leftTooltip: 'Queue',
    rightTooltip: '',
    leftActionDisabled: false,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: REQUEST_STATUS.Queued,
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.PickingUp]: {
    leftTooltip: 'Confirmed',
    rightTooltip: 'Arrived',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: REQUEST_STATUS.ArrivedAtPickup,
    prevStatus: REQUEST_STATUS.Confirmed,
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.ArrivedAtPickup]: {
    leftTooltip: 'Picking Up',
    rightTooltip: 'Start Trip',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: REQUEST_STATUS.StartPickup,
    prevStatus: REQUEST_STATUS.PickingUp,
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.EnRouteToShop]: {
    leftTooltip: 'Arrived',
    rightTooltip: 'Dropped Off',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: REQUEST_STATUS.DropOffAtShop,
    prevStatus: REQUEST_STATUS.ArrivedAtPickup,
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.DropOffAtShop]: {
    leftTooltip: 'En Route',
    rightTooltip: 'Complete',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: 'Completed',
    prevStatus: REQUEST_STATUS.EnRouteToShop,
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Assigned]: {
    leftTooltip: 'Unassign',
    rightTooltip: 'Start',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'unassign',
    rightTooltipStyleClass: 'start',
    nextStatus: 'work_in_progress',
    prevStatus: 'unassigned',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.AssignedToSP]: {
    leftTooltip: 'Cancel',
    rightTooltip: 'Accept',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'light_red',
    rightTooltipStyleClass: 'light_blue',
    nextStatus: REQUEST_STATUS.Accepted,
    prevStatus: REQUEST_STATUS.CancelledServiceProvider,
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.PendingContractor]: {
    leftTooltip: '',
    rightTooltip: 'Process',
    leftActionDisabled: true,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: 'process',
    nextStatus: 'work_in_progress',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.AttentionRequiredPayment]: {
    leftTooltip: '',
    rightTooltip: 'Pay Now',
    leftActionDisabled: true,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: 'pay',
    nextStatus: 'attention_required_payment_paid',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.AttentionRequiredPaymentPaid]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.AttentionRequiredQuery]: {
    leftTooltip: '',
    rightTooltip: 'Resolve',
    leftActionDisabled: true,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: 'resolve',
    nextStatus: 'attention_required_query_resolved',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.AttentionRequiredQueryPaid]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.AttentionRequiredQueryResolved]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.CancelledDoorman]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.CancelledFrontdesk]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.CancelledManagement]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.CancelledServiceProvider]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.CancelledTenant]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.CancelledContractor]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.ConfirmedDoorman]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.ConfirmedFrontdesk]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.ConfirmedTenant]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.DeliveredDoorman]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.DeliveredFrontdesk]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.DeliveredTenant]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.FeedbackAdded]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.NewScheduledRequest]: {
    leftTooltip: '',
    rightTooltip: 'Accept',
    leftActionDisabled: true,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: 'accept',
    nextStatus: 'accepted',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.OutForDelivery]: {
    leftTooltip: 'Back',
    rightTooltip: 'Delivered',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'back',
    rightTooltipStyleClass: 'delivered',
    nextStatus: 'delivered',
    prevStatus: 'work_done',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Paid]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.PersonnelConfirmed]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.PersonnelDeclined]: {
    leftTooltip: '',
    rightTooltip: '',
    leftActionDisabled: true,
    rightActionDisabled: true,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: '',
    nextStatus: '',
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.PersonnelPending]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Processing]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Requesting]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.SameAbove]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.ServiceProvider]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.StartPickup]: {
    leftTooltip: '',
    rightTooltip: 'Arrived',
    leftActionDisabled: true,
    rightActionDisabled: false,
    leftTooltipStyleClass: '',
    rightTooltipStyleClass: 'light_blue',
    nextStatus: REQUEST_STATUS.ArrivedAtPickup,
    prevStatus: '',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.TipAdded]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.Unassigned]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.WorkDone]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.WorkInProgress]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  [REQUEST_STATUS.AcceptedBySP]: {
    leftTooltip: 'Decline',
    rightTooltip: 'Confirm',
    leftActionDisabled: false,
    rightActionDisabled: false,
    leftTooltipStyleClass: 'decline',
    rightTooltipStyleClass: 'confirm',
    nextStatus: 'personnel_confirmed',
    prevStatus: 'personnel_declined',
    leftSpinner: false,
    rightSpinner: false,
  },
  // ... other status configurations
} as const;
